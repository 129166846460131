import { useState, useEffect } from 'react';

function JSONLoader({ jsonPath, columns }) {
  
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(jsonPath);
        const jsonData = await response.json();

        // Sélectionnez les colonnes spécifiques que vous souhaitez utiliser
        const selectedColumns = jsonData.map(item => {
          const selectedData = {};
          for (const column of columns) {
            selectedData[column] = item[column];
          }
          return selectedData;
        });

        //console.log(selectedColumns);

        setData(selectedColumns);
      } catch (error) {
        console.error("Erreur lors du chargement du fichier JSON :", error);
      }
    };

    fetchData();
  }, [jsonPath, columns]);

  return data;
}

export default JSONLoader;