import React, { useState, useEffect } from 'react';
import style_typo from '../components/ThemeProvider';
import DataTabs from './DataTabs';
import JSONLoader from '../data_loader/JSONLoader';
import BoxPlot from '../charts/Boxplot';
import Select from 'react-select';
//import Dropdown from 'react-bootstrap/Dropdown';
import '../css/ThemeGeneral.css';
//import '../css/ButtonDropdown.css';
//import Grid from '@mui/material/Grid';
import HeatMap from '../charts/Heatmap';
import { Typography } from '@mui/material';
//import URLDataCrime from '../charts_loader/URLLoader'
import Scatter from '../charts/Scatter';
import ScatterCluster from '../charts/Scatter_Clusters';
//import BubbleMap from '../charts/Map';

export default function Data() {

  // how to custom style an element see select
  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     height: '30px', // Ajustez la hauteur selon vos préférences
  //     minHeight: '30px', // Ajustez la hauteur minimale si nécessaire
  //     borderRadius: '4px', // Ajoutez des coins arrondis si nécessaire
  //   }),
  //   // Ajoutez d'autres styles personnalisés selon vos besoins
  // };

  // data boxplot stations - dataset random
  const jsonPath = '../data/data_stations_psistema.json';
  const columns = ['station', 'date', 'hour', 'downtime_sec'];
  const data = JSONLoader({ jsonPath, columns });
  const uniqueStations = [...new Set(data.map(item => item.station))];
  const defaultStation = uniqueStations[0]; 
  const [selectedStation, setSelectedStation] = useState(defaultStation);

  const stationOptions = uniqueStations.map(station => ({ value: station, label: station }));

  const handleStationChange = selectedOption => {
    setSelectedStation(selectedOption.value);
  };
  
  useEffect(() => {
    // Générer le graphique avec la station par défaut
    handleStationChange({ value: defaultStation, label: defaultStation });
  }, [defaultStation]);

  // Fonction pour encapsuler le Dropdown
  // const renderDropdown = () => (
  //   <Dropdown onSelect={handleStationChange}>
  //     <Dropdown.Toggle id="dropdown-basic">
  //       {selectedStation}
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu>
  //       {stationOptions.map(option => (
  //         <Dropdown.Item key={option.value} eventKey={option.value}>
  //           {option.label}
  //         </Dropdown.Item>
  //       ))}
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );

  // data heatmap - dataset source kaggle
  const heatmapJsonPath = '../data/data_hr_metrics_psistema.json';
  const heatmapColumns = ['genre', 'age', 'satisfaction','evaluation','project','hours','spend','accident','project','depart','sales','promotion','salary'];
  const heatmapData = JSONLoader({ jsonPath: heatmapJsonPath, columns: heatmapColumns });

  // data cars - dataset source kaggle
  const scatterJsonPath = '../data/data_selling_car_psistema.json';
  const scatterColumns = ['selling_price','km_driven'];
  const scatterData = JSONLoader({ jsonPath: scatterJsonPath, columns: scatterColumns });

  // data health - dataset created aleaotry
  const scatterclusterJsonPath = '../data/data_health_clusters_psistema.json';
  const scatterclusterColumns = ['BloodPressure','Cholesterol','cluster'];
  const scatterclusterData = JSONLoader({ jsonPath: scatterclusterJsonPath, columns: scatterclusterColumns });

  const tabs = [
    {
      label: 'boxplot', 
      content: [
        <div key="boxplot-content">
          {/* {renderDropdown()} */}
          <Select
            options={stationOptions}
            value={{ value: selectedStation, label: selectedStation }}
            onChange={handleStationChange}
            //styles={customStyles}
            styles={{
              control: (provided, state) => ({
                ...provided,
                width: '80%', // Ajustez la largeur selon vos préférences
              }),
              // Ajoutez d'autres styles personnalisés selon vos besoins
            }}
          />
          <BoxPlot data={data} station={selectedStation} />  
        </div>,
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
            <div>
            <b>objective :</b>
              <ul className="space-between">
                <li>distribution of station stopping times for each hour in a specific selection of stations. Each box represents a time of day, and the height of the box illustrates the dispersion of dwell times, providing insights into temporal variability at each station</li>
              </ul>
            </div>
          </Typography>
      ]
    },
    {
      label: 'scatter',
      content: [
        <Scatter key="cars" data={scatterData} />,
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
        <div>
        <b>objective :</b>
          <ul className="space-between">
            <li>illustrates the relationship between the distance traveled (km driven) and the selling price (selling price) of vehicles</li>
            <li>the regression line cuts through the scatterplot in a downward slope, illustrating the general trend in the relationship between distance traveled and sales price. This line is adjusted to minimize deviations between the actual points and the line, providing a linear estimate of the relationship</li>
          </ul>
        </div>
        </Typography>
         
      ]
    },
    {
      label: 'heatmap',
      content: [
        <HeatMap key="heatmap" data={heatmapData} />,
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
        <div>
        <b>objective :</b>
          <ul className="space-between">
            <li>visual representation of the correlation between employee satisfaction, their evaluations and their decision to leave the company or not</li>
            <li>indicate the frequency of departure or non-departure based on satisfaction levels and evaluations</li>
            <li>involve identifying specific trends, clusters, or combinations of satisfaction and ratings associated with particular departure rates</li>
          </ul>
        </div>
        </Typography>
         
      ]
    },
    {
      label: 'k-means',
      content: [
        <ScatterCluster key="health" data={scatterclusterData} />,
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
        <div>
        <b>objective :</b>
          <ul className="space-between">
            <li>represents a specific observation with blood pressure and cholesterol values. These points are colored according to their membership in one of the five clusters identified by the K-Means algorithm</li>
            <li>the color of the points indicates the cluster to which they belong</li>
            <li>allows you to identify categories in the entire data set</li>
          </ul>
        </div>
      </Typography>

      ]
    },
    // {
    //   label: 'url',
    //   content: [
    //     <URLDataCrime />
    //   ]
    // },
  ]

  return <DataTabs tabs={tabs} />;

  // return (
  //   <>
  //     {/* Utilisez le composant Grid pour créer une disposition réactive */}
  //     <Grid container spacing={3}>
  //       <Grid item xs={12} md={8}>
  //         {/* Contenu de la première colonne */}
  //         <Dropdown onSelect={handleStationChange}>
  //           <Dropdown.Toggle id="dropdown-basic">
  //             {selectedStation}
  //           </Dropdown.Toggle>
  //           <Dropdown.Menu>
  //             {stationOptions.map(option => (
  //               <Dropdown.Item key={option.value} eventKey={option.value}>
  //                 {option.label}
  //               </Dropdown.Item>
  //             ))}
  //           </Dropdown.Menu>
  //         </Dropdown>
  //         <BoxPlot data={data} station={selectedStation} />
  //       </Grid>
  //       <Grid item xs={12} md={4}>
  //         {/* Contenu de la deuxième colonne */}
  //         <HeatMap data={heatmapData} />
  //       </Grid>
  //     </Grid>
  //   </>
  // );
}
