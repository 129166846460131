import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import '../css/ZoneUpload.css';

function FileUploader({ setHeaders, setCsvData }) {
    const [fileName, setFileName] = useState('');
    const handleFile = (file, callback) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      setFileName(file.name); // Set the file name

      let parsedData;

      try {
        parsedData = JSON.parse(data);
        
        console.log('Données JSON :', parsedData);

        if (Array.isArray(parsedData) && parsedData.length > 0 && typeof parsedData[0] === 'object') {
          const headers = Object.keys(parsedData[0]);
          setHeaders(headers);

          // allow to pass an array of objects or an array of arrays to the callback function (without headers)
          // const csvData = parsedData.map((row) => Object.values(row));
          // callback(csvData);

          const csvData = parsedData;
          callback(csvData);

          return;
          
        }
      } catch (jsonError) {
        console.error('Erreur lors de la lecture du fichier JSON :', jsonError);
      }

      Papa.parse(file, {
        header: true, // Indique à Papa Parse que la première ligne est une ligne d'en-tête
        skipEmptyLines: true, // Ignorer les lignes vides
        complete: (result) => {
          //console.log('Papa Parse Result:', result);
          const headers = result.meta.fields; // Utilisez result.meta.fields pour obtenir les entêtes
          setHeaders(headers);
      
          const csvData = result.data;
          callback(csvData);
          
        },
      });
    };

    // Check if the file is XLSX
    if (file.name.endsWith('.xlsx')) {
      const xlsxReader = new FileReader();
      xlsxReader.onload = (e) => {
        const data = e.target.result;
        setFileName(file.name); // Set the file name for XLSX files
        const workbook = XLSX.read(data, { type: 'binary' });
    
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const xlsxData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
        // Vérifier si des en-têtes sont disponibles
        if (xlsxData.length > 1) {
          setHeaders(xlsxData[0]); // Définir les en-têtes
    
          // Extraire les données à partir de la deuxième ligne
          const csvData = xlsxData.slice(1).map(row => {
            const rowData = {};
            xlsxData[0].forEach((header, index) => {
              rowData[header] = row[index];
            });
            return rowData;
          });
    
          callback(csvData);
          //console.log("csvData", csvData);
        } else {
          console.error('Le fichier xlsx ne contient pas de données.');
        }
    
        //console.log("xlsxData", xlsxData);
      };
    
      xlsxReader.readAsBinaryString(file);
    } else {
      // For JSON and CSV files
      reader.readAsText(file);
    }
  }    

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFile(file, setCsvData);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className="zone-upload">
      <input {...getInputProps()} />
      <p>drag and drop a file here or click to select a file</p>
      <p>supported formats [ json, csv, xlsx ]</p>
      {fileName && <p>file name | {fileName}</p>}
    </div>
  );
}

export default FileUploader;
