import { createTheme, ThemeProvider } from '@mui/material/styles';

const style_typo = createTheme({
  typography: {
    fontFamily: 'Consolas, monospace',
    // align: 'justify',
  },

  //Pour react-select, la propriété width n'est pas gérée par le thème MUI via createTheme. 
  //Vous devrez l'ajuster directement dans le composant Select.

  select: {
    control: (provided, state) => ({
      ...provided,
      //height: '30px', // Ajustez la hauteur selon vos préférences
      //minHeight: '30px', // Ajustez la hauteur minimale si nécessaire
      //borderRadius: '4px', // Ajoutez des coins arrondis si nécessaire
    }),
  }
});


export default style_typo;
