import React from 'react';
import Button from '@mui/material/Button';
//import BarChartIcon from '@mui/icons-material/BarChart';
import { styled } from '@mui/material/styles';

function ButtonEnter() {
  
  const CustomButton = styled(Button)({
    opacity: 0.3,
    backgroundColor: '#BDFDFF',
    color: '#212529',//'#0095AB',
    fontWeight: 'bold',
    borderRadius: '3px',
    position: 'absolute',
    top: '90%',
    left: '6%',
    '&:hover': {
      opacity: 0.5,
      backgroundColor: '#BDFDFF',
    }
  });

  return (
    <CustomButton variant='contained'>
      {/* <span style={{ marginRight: '5px' }}><BarChartIcon /></span> */}
      Enter
    </CustomButton>
  );
}

export default ButtonEnter;
  