import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

function Barplot({ data }) {
  const [BarplotData, setBarplotData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const agressionCount = data.reduce((acc, cur) => {
        acc[cur.Quartier] = (acc[cur.Quartier] || 0) + 1;
        return acc;
      }, {});

      const extractBarplotData = {
        x: Object.keys(agressionCount),
        y: Object.values(agressionCount),
        type: 'bar',
        //mode: 'markers+text',
        text: Object.values(agressionCount),
        textposition: 'auto',
      };

      setBarplotData([extractBarplotData]);
    }
  }, [data]);

  const layout = {
    title: 'number of aggressions per neighborhood',
    font: {
      family: 'Consolas, monospace', // Définissez votre police souhaitée
    },
    xaxis: {
      //title: 'Neighborhood',
    },
    yaxis: {
      //title: 'Number of Aggressions',
    },
  };

  return <Plot data={BarplotData} layout={layout} />;
}

export default Barplot;