import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

function Scatter({ data }) {
    const [scatterData, setScatterData] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            // Assurez-vous que les données sont correctement chargées avant de les traiter
            const extractScatterData = {
                x: data.map(item => item.km_driven),
                y: data.map(item => item.selling_price),
                type: 'scatter',
                mode: 'markers',
                marker: { size: 12 },
                showlegend: false
            };

            // Calculer la régression linéaire
            const x = extractScatterData.x;
            const y = extractScatterData.y;
            const n = x.length;

            const meanX = x.reduce((acc, val) => acc + val, 0) / n;
            const meanY = y.reduce((acc, val) => acc + val, 0) / n;

            const numerator = x.reduce((acc, val, index) => acc + (val - meanX) * (y[index] - meanY), 0);
            const denominator = x.reduce((acc, val) => acc + Math.pow(val - meanX, 2), 0);

            const slope = numerator / denominator;
            const intercept = meanY - slope * meanX;

            // Créer la trace pour la régression linéaire
            const regressionData = {
                type: 'line',
                x: [Math.min(...x), Math.max(...x)],
                y: [slope * Math.min(...x) + intercept, slope * Math.max(...x) + intercept],
                mode: 'lines',
                line: {
                    color: 'red',
                    dash: 'dash',
                },
                showlegend: false // Cacher la trace
                //name: 'Régression Linéaire',
            };

            setScatterData([extractScatterData,regressionData]);
        }
    }, [data]);

    const layout = {
        title: 'relationship between two variables and linear regression',
        font: {
            family: 'Consolas, monospace', // Définissez votre police souhaitée
          },
        xaxis: {
            title: 'km driven',
        },
        yaxis: {
            title: 'selling price',
        },
        autosize: true, // Activez l'ajustement automatique de la taille
        //width: '100%',
    };

    return <Plot data={scatterData} layout={layout}/>;
}

export default Scatter;