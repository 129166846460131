import React, { useState, useEffect } from 'react';
import imagesList from '../imagesList'; // Importez la liste des images
import { Link } from 'react-router-dom';
import ButtonEnter from '../components/ButtonEnter';
import '../css/Home.css';
//import ButtonIconeFinger from '../ui/button_icone_finger';

function Home() {
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesList.length);
    }, 8000); // Change d'image toutes les 8 secondes (8000 ms)

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
    <div className="main-page">
      {imagesList.map((image, index) => (
        <img
          key={index}
          src={`images/${image}`}
          alt={`Photo ${index + 1}`}
          className={index === currentImageIndex ? 'active' : ''}
        />
      ))}

      <div className="title-container">
          <h1 className="title">PSISTEMA</h1>
          <h2 className="subtitle">MAKE YOUR DATAS SPEAK</h2>
          <div className="button-home-container">
          <Link to="/Descriptive">
            <ButtonEnter />
          </Link>
          </div>
          {/* <ButtonIconeFinger to="/Data" /> */}

      </div>
    </div>
    </>
  );

}

export default Home;