import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

function LineChart({ data }) {
  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      // Convertir la date en objet Date pour extraire le mois
      const formattedData = data.map(item => ({
        ...item,
        Date: new Date(item.Date),
      }));

      // Compter le nombre d'agressions par mois
      const agressionsByMonth = formattedData.reduce((acc, cur) => {
        const month = cur.Date.getMonth();
        acc[month] = (acc[month] || 0) + 1;
        return acc;
      }, {});

      // Calculer la moyenne mobile sur les derniers n mois
      const n = 3; // Vous pouvez ajuster le nombre de mois selon vos besoins
      const movingAverage = Object.values(agressionsByMonth).map((count, index, array) => {
        const start = Math.max(0, index - n + 1);
        const end = index + 1;
        const sum = array.slice(start, end).reduce((total, value) => total + value, 0);
        return (sum / (end - start)).toFixed(2); // Arrondir a deux chiffres apres la virgule
      });

      // Calculer la moyenne mobile sur 12 mois
      const ytdAverage = [];
      let sum = 0;

      for (let i = 0; i < 12; i++) {
        sum += Object.values(agressionsByMonth)[i];
        ytdAverage.push((sum / (i + 1)).toFixed(2));
      }

      const extractLineChartData = {
        x: Object.keys(agressionsByMonth).map(month => parseInt(month, 10) + 1), // Ajouter 1 pour obtenir des mois de 1 à 12
        y: Object.values(agressionsByMonth),
        type: 'line',
        mode: 'lines+markers+text',
        text: Object.values(agressionsByMonth).map(count => count.toString()),
        name : '# of aggressions',
        textposition: 'top',
      };

      const movingAverageLine = {
        x: Object.keys(agressionsByMonth).map(month => parseInt(month, 10) + 1),
        y: movingAverage,
        type: 'line',
        mode: 'lines+markers+text',
        name: `mov. avg. [${n} months]`,
        text : movingAverage,
        textfont: {
          color: 'orange',  // Assurez-vous que c'est la même couleur que la ligne
        },
        textposition: 'bottom',
      };
      
      const ytdLine = {
        x: Object.keys(agressionsByMonth).map(month => parseInt(month, 10) + 1),
        y: ytdAverage,
        type: 'line',
        mode: 'lines+markers+text',
        name: 'YTD',
        text : ytdAverage,
        textfont: {
          color: 'green',  // Assurez-vous que c'est la même couleur que la ligne
    
        },
        textposition: 'auto',
      };

      setLineChartData([extractLineChartData, movingAverageLine, ytdLine]);
    }
  }, [data]);

  const layout = {
    title: 'count of aggressions per month <br> moving average - YTD',
    font: {
      family: 'Consolas, monospace', // Définissez votre police souhaitée
    },
    xaxis: {
      //title: 'Month',
      tickmode: 'array',
      tickvals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      ticktext: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
    yaxis: {
      //title: 'Number of Aggressions',
    },
  };

  return <Plot data={lineChartData} layout={layout} />;
}

export default LineChart;