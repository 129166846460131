import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

function HeatMap({ data }) {
  const [heatmapData, setHeatmapData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      // Assurez-vous que les données sont correctement chargées avant de les traiter
      const extractHeatmapData = {
        x: data.map(item => item.satisfaction),
        y: data.map(item => item.evaluation),
        z: data.map(item => item.depart),
        type: 'heatmap',
        colorscale: 'Portland',
        showscale: false
      };
      setHeatmapData([extractHeatmapData]);
    }
  }, [data]);

  const layout = {
    title: 'relationship between three variables',
    font: {
      family: 'Consolas, monospace', // Définissez votre police souhaitée
    },
    xaxis: {
      title: 'satisfaction',
    },
    yaxis: {
      title: 'evaluation',
    },
    autosize: true, // Activez l'ajustement automatique de la taille
    //width: '100%',
  };

  return <Plot data={heatmapData} layout={layout}/>;
  // return <div style={{ width: '100%' }}><Plot data={heatmapData} layout={layout} /></div>;
  // return <Plot data={heatmapData} layout={layout} style={{ width: '100%' }} />;
}

export default HeatMap;
