import React, { useState, useEffect } from 'react';
import style_typo from '../components/ThemeProvider';
import DataTabs from './DataTabs';
import JSONLoader from '../data_loader/JSONLoader';
import '../css/ThemeGeneral.css';
import '../css/ButtonDropdown.css';
//import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import FileUploader from '../components/Dropzone';
import DynamicGraph from '../charts/UploadBarplot';
import Select from 'react-select';

import Plot from 'react-plotly.js';


export default function Data() {

  //const [error, setError] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);

  const [selectedXAxis, setSelectedXAxis] = useState(null);
  const [selectedYAxis, setSelectedYAxis] = useState(null);
  const [selectedZAxis, setSelectedZAxis] = useState(null);
  const [selectedChartType, setSelectedChartType] = useState(null);

  const handleFileProcessed = (headers) => {
    setSelectedHeaders(headers);
  };
  
  const tabs = [
    {
      label: 'Upload Your File',
      content: [
        // <FileUploader setHeaders={handleFileProcessed} setCsvData={setCsvData} setError={setError}/>, {error && <div style={{ color: 'red' }}>{error}</div>},
        <div>
        <FileUploader setHeaders={handleFileProcessed} setCsvData={setCsvData} />
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
           <Select name='select_xaxis' options={selectedHeaders.map((header) => ({ value: header, label: header }))} onChange={(selectedOption) => setSelectedXAxis(selectedOption.value)} placeholder="Select the X axis"/>
           <Select name='select_yaxis' options={selectedHeaders.map((header) => ({ value: header, label: header }))} onChange={(selectedOption) => setSelectedYAxis(selectedOption.value)} placeholder="Select the Y axis"/>
           <Select name='select_zaxis' options={selectedHeaders.map((header) => ({ value: header, label: header }))} onChange={(selectedOption) => setSelectedZAxis(selectedOption.value)} placeholder="Select the Z axis"/>
           <Select name='select_chart' options={[{ value: 'bar', label: 'Bar Plot' }, { value: 'scatter', label: 'Scatter Plot' }]} onChange={(selectedOption) => setSelectedChartType(selectedOption.value)} placeholder="Select Chart Type"/>
        </Typography>
        <DynamicGraph
          csvData={csvData}
          selectedXAxis={selectedXAxis}
          selectedYAxis={selectedYAxis}
          selectedZAxis={selectedZAxis}
          selectedChartType={selectedChartType}
        />
        </div>
      ]
    },
  ]

  return <DataTabs tabs={tabs} />;
}
