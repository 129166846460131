// DataTabs.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Grid, Box } from '@mui/material';

function CustomTabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && (
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
          {/* Première ligne */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              {/* Contenu de la première colonne */}
              {children[0]}
            </Grid>
            <Grid item xs={12} md={4}>
              {/* Contenu de la deuxième colonne */}
              {children[1]}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              {/* Contenu de la première colonne */}
              {children[2]}
            </Grid>
            <Grid item xs={12} md={4}>
              {/* Contenu de la deuxième colonne */}
              {children[3]}
            </Grid>
          </Grid>
          {/* Deuxième ligne */}
          {/* ... (autres lignes) */}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function DataTabs({ tabs }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} aria-label="Data Tabs">
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} {...a11yProps(index)} />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

DataTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default DataTabs;
