import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

function BubbleMap({ data }) {
  const [isSampled, setIsSampled] = useState(false);
  const [sampledData, setSampledData] = useState([]);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  useEffect(() => {
    if (data && data.length > 0 && !isSampled) {
      // Capturer un échantillon aléatoire une seule fois
      const sampledData = data.sort(() => 0.5 - Math.random()).slice(0, 500);
      setIsSampled(true);
      setSampledData(sampledData);
    }
  }, [data, isSampled]);

  const agressionCount = sampledData.reduce((acc, cur) => {
    acc[cur.Quartier] = (acc[cur.Quartier] || 0) + 1;
    return acc;
  }, {});

  const handleMarkerMouseOver = (quartier) => {
    setHoveredMarker(quartier);
  };

  const handleMarkerMouseOut = () => {
    setHoveredMarker(null);
  };

  const bubbleData = Object.keys(agressionCount).map((quartier) => {
    const item = sampledData.find((dataItem) => dataItem.Quartier === quartier);
    const marker = L.divIcon({
      className: 'custom-div-icon',
      html: `<div style="background-color: blue; opacity: 0.3; border-radius: 50%; width: ${agressionCount[quartier]}px; height: ${agressionCount[quartier]}px;"></div>`,
    });

    return (
      <Marker
        key={quartier}
        position={[item.Latitude, item.Longitude]}
        icon={marker}
        eventHandlers={{
          mouseover: () => handleMarkerMouseOver(quartier),
          mouseout: handleMarkerMouseOut,
        }}
      >
        {hoveredMarker === quartier && (
          <Tooltip direction="bottom" offset={[0, 20]} opacity={0.6}>
            {`${quartier} : ${agressionCount[quartier]}`}
          </Tooltip>
        )}
      </Marker>
    );
  });

  return (
    <MapContainer center={[34.0522, -118.2437]} zoom={10} style={{ height: '400px', border: '1px solid black', width: '90%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {bubbleData}
    </MapContainer>
  );
}

export default BubbleMap;
