import React, { useState, useEffect } from 'react';
import style_typo from '../components/ThemeProvider';
import DataTabs from './DataTabs';
import JSONLoader from '../data_loader/JSONLoader';
import '../css/ThemeGeneral.css';
import '../css/ButtonDropdown.css';
//import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
//import URLDataCrime from '../charts_loader/URLLoader'
import Barplot from '../charts/Barplot';
import LineChart from '../charts/Line';
//import BubbleMap from '../charts/Map';
import Pieplot from '../charts/Pieplot';
import CityPopulationPlot from '../charts/Map';
import FileUploader from '../components/Dropzone';
import DynamicGraph from '../charts/UploadBarplot';
import Select from 'react-select';

import Plot from 'react-plotly.js';


export default function Data() {

  // data agression - dataset created aleaotry
  const AggressionJsonPath = '../data/data_agression_psistema.json';
  const AggressionColumns = ['Quartier','Latitude','Longitude','Date','Type_Agression','Age_Agresseur','Age_Agresse','Genre_Agresse'];
  const AggressionplotData = JSONLoader({ jsonPath: AggressionJsonPath, columns: AggressionColumns });
  
  //const [error, setError] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);

  const [selectedXAxis, setSelectedXAxis] = useState(null);
  const [selectedYAxis, setSelectedYAxis] = useState(null);
  const [selectedZAxis, setSelectedZAxis] = useState(null);
  const [selectedChartType, setSelectedChartType] = useState(null);

  const handleFileProcessed = (headers) => {
    setSelectedHeaders(headers);
  };
  
  const tabs = [
    {
      label: 'barplot',
      content: [
        <Barplot key="agression" data={AggressionplotData} />,
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
           <div>
           <b>objective :</b>
           <ul className="space-between">
            <li>quick identification of neighborhoods with the highest levels of aggressions, as well as those with relatively low numbers</li>
            <li>the barplot clearly illustrates the distribution of the number of agrressions in different areas of the city. Each bar represents a specific neighborhood, with the height of the bar indicating the total number of recorded aggressions</li>
           </ul>
           </div>
        </Typography>
      ]
    },
    {
      label: 'pieplot',
      content: [
        <Pieplot key="agression" data={AggressionplotData} />,
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
           <div>
           <b>objective :</b>
           <ul className="space-between">
            <li>quickly identify the predominant types of aggression, represented by the largest sectors</li>
            <li>each sector of the circle represents a specific type of aggression, and the size of each sector is proportional to the relative frequency of that incident compared to all recorded aggression</li>
           </ul>
          </div>
        </Typography>,
      ]
    },
    {
      label: 'line',
      content: [
          <LineChart key="line" data={AggressionplotData}/>,   
          <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
            <div>
              <b>Objective:</b>
              <ul className="space-between">
                <li>
                temporal analysis of the number of aggressions by month, with a clear representation of the overall trend,
                3-month moving average, and Year-to-Date (YTD) accumulation
                </li>
                <li>
                3-month moving average, providing a smoothed perspective of the longer-term trend. This line smoothes out
                monthly fluctuations
                </li>
                <li>
                Year-to-Date, illustrating the cumulative number of aggressions from the start of the year to the current
                month. This line allows you to visualize the continued growth in the total number of aggressions² over time
                </li>
              </ul>
            </div>
           </Typography>,
      ]
    },
    {
      label: 'bubblemap',
      content: [
        // <BubbleMap key="map" data={AggressionplotData} />,
        <CityPopulationPlot key="map" data={AggressionplotData} />,
        <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
           <div>
           <b>Objective:</b>
           <ul className="space-between">
            <li>this interactive representation offers a spatial view of the concentrations of aggressions in different geographic areas</li>
            <li>the size of the bubble is proportional to the number of attacks recorded in this area. This visualization allows you to quickly visualize high-risk areas based on the volume of aggressions</li>
           </ul>
           </div>
        </Typography>
      ]
    },
    // {
    //   label: 'Uploader',
    //   content: [
    //     // <FileUploader setHeaders={handleFileProcessed} setCsvData={setCsvData} setError={setError}/>, {error && <div style={{ color: 'red' }}>{error}</div>},
    //     <div>
    //     <FileUploader setHeaders={handleFileProcessed} setCsvData={setCsvData} />
    //     <Typography key="text" variant="body1" theme={style_typo} className="justify-text">
    //        <Select name='select_xaxis' options={selectedHeaders.map((header) => ({ value: header, label: header }))} onChange={(selectedOption) => setSelectedXAxis(selectedOption.value)} placeholder="Select the X axis"/>
    //        <Select name='select_yaxis' options={selectedHeaders.map((header) => ({ value: header, label: header }))} onChange={(selectedOption) => setSelectedYAxis(selectedOption.value)} placeholder="Select the Y axis"/>
    //        <Select name='select_zaxis' options={selectedHeaders.map((header) => ({ value: header, label: header }))} onChange={(selectedOption) => setSelectedZAxis(selectedOption.value)} placeholder="Select the Z axis"/>
    //        <Select name='select_chart' options={[{ value: 'bar', label: 'Bar Plot' }, { value: 'scatter', label: 'Scatter Plot' }]} onChange={(selectedOption) => setSelectedChartType(selectedOption.value)} placeholder="Select Chart Type"/>
    //     </Typography>
    //     <DynamicGraph
    //       csvData={csvData}
    //       selectedXAxis={selectedXAxis}
    //       selectedYAxis={selectedYAxis}
    //       selectedZAxis={selectedZAxis}
    //       selectedChartType={selectedChartType}
    //     />
    //     </div>
    //   ]
    // },
  ]

  return <DataTabs tabs={tabs} />;

  // return (
  //   <>
  //     {/* Utilisez le composant Grid pour créer une disposition réactive */}
  //     <Grid container spacing={3}>
  //       <Grid item xs={12} md={8}>
  //         {/* Contenu de la première colonne */}
  //         <Dropdown onSelect={handleStationChange}>
  //           <Dropdown.Toggle id="dropdown-basic">
  //             {selectedStation}
  //           </Dropdown.Toggle>
  //           <Dropdown.Menu>
  //             {stationOptions.map(option => (
  //               <Dropdown.Item key={option.value} eventKey={option.value}>
  //                 {option.label}
  //               </Dropdown.Item>
  //             ))}
  //           </Dropdown.Menu>
  //         </Dropdown>
  //         <BoxPlot data={data} station={selectedStation} />
  //       </Grid>
  //       <Grid item xs={12} md={4}>
  //         {/* Contenu de la deuxième colonne */}
  //         <HeatMap data={heatmapData} />
  //       </Grid>
  //     </Grid>
  //   </>
  // );
}
