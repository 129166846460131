import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

function Pieplot({ data }) {
  const [pieplotData, setPieplotData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const typeAgressionCount = data.reduce((acc, cur) => {
        acc[cur.Type_Agression] = (acc[cur.Type_Agression] || 0) + 1;
        return acc;
      }, {});

      // Définir les couleurs pour chaque tranche
      const couleurs = ['gold', 'lightskyblue', 'lightcoral', 'lightgreen'];

      const values = Object.values(typeAgressionCount);
      const maxIndex = values.indexOf(Math.max(...values));

      // Construire le tableau pull avec une valeur élevée pour la tranche maximale et 0 pour les autres
      const pull = values.map((val, index) => (index === maxIndex ? 0.07 : 0.0));

      const extractPieplotData = {
        labels: Object.keys(typeAgressionCount),
        values: Object.values(typeAgressionCount),
        type: 'pie',
        textposition: 'auto',
        hoverinfo: 'label+percent', // Affiche le pourcentage dans les infobulles
        marker: {
            colors: couleurs,
            line: {
              color: 'white', // Couleur de la ligne entre les tranches
              width: 2, // Largeur de la ligne
            },
          },
        pull: pull // [0.0, 0.0, 0.0, 0.1], // Pull la plus grande tranche du centre
      };

      setPieplotData([extractPieplotData]);
    }
  }, [data]);

  const layout = {
    title: 'percentage of aggressions by type',
    font: {
      family: 'Consolas, monospace', // Définissez votre police souhaitée
    },
  };

  return <Plot data={pieplotData} layout={layout} />;
}

export default Pieplot;
