import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

function ScatterCluster({ data }) {
    const [scatterData, setScatterData] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            // Assurez-vous que les données sont correctement chargées avant de les traiter
            const extractScatterData = {
                x: data.map(item => item.BloodPressure),
                y: data.map(item => item.Cholesterol),
                type: 'scatter',
                mode: 'markers',
                marker: {
                    size: 12,
                    color: data.map(item => item.cluster),
                    colorscale: 'Viridis',
                    colorbar: {
                        //title: 'Cluster',
                        thickness: 20,
                        tickvals: data.map(item => item.cluster),
                        ticktext: data.map(item => `Cluster ${item.cluster}`),
                    },
                },
                text: data.map(item => `Cluster: ${item.cluster}`),
                //showlegend: true,
            };

            setScatterData([extractScatterData]);
        }
    }, [data]);

    const layout = {
        title: 'identification of characteristics by clusters',
        font: {
            family: 'Consolas, monospace', // Définissez votre police souhaitée
          },
        xaxis: {
            title: 'bloodPressure',
        },
        yaxis: {
            title: 'cholesterol',
        },
        autosize: true,
        //width: '100%',
    };

    return <Plot data={scatterData} layout={layout}/>;
}

export default ScatterCluster;
