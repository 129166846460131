import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

function DynamicGraph({ csvData, selectedXAxis, selectedYAxis, selectedZAxis, selectedChartType }) {
  const [plotData, setPlotData] = useState([]);

  useEffect(() => {
    //console.log('Props in DynamicGraph:', csvData, selectedXAxis, selectedYAxis, selectedZAxis, selectedChartType);
    if (csvData && csvData.length > 0 && selectedXAxis && selectedYAxis && selectedChartType) {
      let trace;

      if (selectedChartType === 'bar') {
        const Count = csvData.reduce((acc, cur) => {
          acc[cur[selectedXAxis]] = (acc[cur[selectedXAxis]] || 0) + 1;
          return acc;
        }, {});

        trace = {
          x: Object.keys(Count),
          y: Object.values(Count),
          type: 'bar',
          text: Object.values(Count),
          textposition: 'auto',
        };
      } else if (selectedChartType === 'scatter') {
        trace = {
          x: csvData.map(row => row[selectedXAxis]),
          y: csvData.map(row => row[selectedYAxis]),
          type: 'scatter',
          mode: 'markers',
          marker: { size: 12 },
          showlegend: false
        };
      }

      setPlotData([trace]);
    }
  }, [csvData, selectedXAxis, selectedYAxis, selectedZAxis, selectedChartType]);

  const layout = {
    title: 'Dynamic Chart', // Ajoutez un titre dynamique si nécessaire
    font: {
      family: 'Consolas, monospace',
    },
    xaxis: {
      // title: 'X Axis', // Ajoutez un titre dynamique si nécessaire
    },
    yaxis: {
      // title: 'Y Axis', // Ajoutez un titre dynamique si nécessaire
    },
  };

  return <Plot data={plotData} layout={layout} />;
}

export default DynamicGraph;
