import React from 'react';
import Plot from 'react-plotly.js';
import * as d3 from 'd3';

function BoxPlot({ data, station }) {
  // Vérifiez si les données sont disponibles
  if (!data || data.length === 0) {
    return <div>No data available for the selected station.</div>;
  }

  const filteredData = data.filter(item => item.station === station);

  // Vérifiez si les données filtrées sont disponibles
  if (filteredData.length === 0) {
    return <div>No data available for the selected station.</div>;
  }

  const uniqueHours = Array.from(new Set(filteredData.map(row => row.hour)));
  const colorScale = d3.scaleSequential(d3.interpolateRainbow);

  const boxPlotData = uniqueHours.map((hour, index) => {
    const color = colorScale(index / (uniqueHours.length - 1));
    return {
      x: filteredData.filter(row => row.hour === hour).map(row => row.hour),
      y: filteredData.filter(row => row.hour === hour).map(row => row.downtime_sec),
      type: 'box',
      name: `Hour ${hour}`,
      marker: { color },
      showlegend: false, // Masquer la légende
    };
  });

  const layout = {
    title: `distribution downtime : ${station}`,
    font: {
      family: 'Consolas, monospace', // Définissez votre police souhaitée
    },
    xaxis: { title: 'Hour',
      tickmode: 'array', // Utilisez 'array' pour les valeurs d'linear'
      tickvals: uniqueHours,      
    },
    yaxis: { title: 'Downtime (seconds)' },
    autosize: true, // Activez l'ajustement automatique de la taille
    // width: '100%', // Utiliser une valeur en pourcentage
    // margin: {
    //   l: 40, // Ajustez la marge gauche pour éviter que le titre ne soit coupé
    //   r: 20,
    //   b: 50,
    //   t: 50,
    //   pad: 4,
    // },
  };

  return <Plot data={boxPlotData} layout={layout} />;
}

export default BoxPlot;
